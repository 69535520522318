import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Dropdown } from "react-bootstrap";

import { editData } from "../../hooks/dataEdit.js";
import "./style.css";
import DatePicker from "react-datepicker";
import { CiCalendar } from "react-icons/ci";
import moment from "moment-timezone";

const DataEditModal = ({
  show,
  onHide,
  dataInfo,
  formId,
  template,
  getFormInfo,
  updateMessage,
}) => {
  const [data, setData] = useState(null);
  const [userPosition, setUserPosition] = useState(null);
  const [dependentFieldMap, setDependentFieldMap] = useState(null);
  const [masterFeildName, setMasterFeildName] = useState(null);
  const [errors, setErrors] = useState({});

  // useEffect(() => {
  //     if (navigator.geolocation) {
  //         navigator.geolocation.getCurrentPosition(
  //             (position) => {
  //                 setUserPosition(`(${position.coords.latitude}, ${position.coords.longitude})`)
  //             },
  //             (error) => {
  //                 console.log("Geolocation is not available")
  //             }
  //         )
  //     }
  // }, [])

  useEffect(() => {
    template?.map((field) => {
      if (field.type === "master_choice") {
        setMasterFeildName(field.name);
        setDependentFieldMap(field.dependent_values);
      }
    });
    // use copy of info
    setData({ ...dataInfo });
  }, [dataInfo, template]);

  const handleSubmit = () => {
    const newErrors = {};
    template.forEach((field) => {
      if (field.required && !data[field.name]) {
        newErrors[field.name] = `${field.name} is required`;
      }

      // Trim spaces and validate camelCase format for "reason for failure" field
      if (field.name === "Reason for Failure" && data[field.name]) {
        // Trim spaces from start and end
        data[field.name] = data[field.name].trim();
      }

      if (field.type === "time" && !data[field.name]) {
        console.log("data[field.name] :: ", data[field.name]);
        data[field.name] = undefined;
      }

      if (
        (field.type === "datetime" || field.type === "date") &&
        data[field.name]
      ) {
        if (field.type === "datetime") {
          data[field.name] = moment(data[field.name]).format(
            "YYYY-MM-DDTHH:mm"
          );
        }
        if (field.type === "date") {
          data[field.name] = moment(data[field.name]).format("YYYY-MM-DD");
        }
        // data[field.name] = moment(data[field.name]).format("YYYY-MM-DDTHH:mm")
        // Check if the date is in the future
        if (new Date(data[field.name]) > new Date()) {
          newErrors[field.name] = `${field.name} cannot be in the future`;
        }

        // Check if the date is at least 2 years in the past
        const diffInMilliseconds = Math.abs(
          new Date(data[field.name]) - new Date()
        );
        const diffInDays = Math.floor(
          diffInMilliseconds / (1000 * 60 * 60 * 24)
        );
        const year = moment(data[field.name]).year(); // Extract the year from the date

        // If the time is in future, show error
        if (year < 1000 || year > 9999) {
          newErrors[field.name] = `${field.name} is not a valid year`;
        } else if (
          moment(data[field.name]).isValid() &&
          moment(data[field.name]).isAfter(moment())
        ) {
          newErrors[field.name] = `${field.name} cannot be in the future`;
        } else if (diffInDays > 365 * 2) {
          newErrors[
            field.name
          ] = `${field.name} must be at least 2 years in the past`;
        }
      }
    });

    // Validate Time of sample collection and Time of lab test
    if (
      data["Time of sample collection"] &&
      !moment(data["Time of sample collection"]).isValid()
    ) {
      newErrors[
        "Time of sample collection"
      ] = `Time of sample collection is not a valid date`;
    }
    if (
      data["Time of lab test"] &&
      !moment(data["Time of lab test"]).isValid()
    ) {
      newErrors["Time of lab test"] = `Time of lab Test is not a valid date`;
    }
    // Check if the lab test date is after the sample collection date
    if (
      data["Time of sample collection"] &&
      data["Time of lab test"] &&
      !newErrors["Time of sample collection"] &&
      !newErrors["Time of lab test"]
    ) {
      const sampleCollectionDate = new Date(data["Time of sample collection"]);
      const labTestDate = new Date(data["Time of lab test"]);
      if (labTestDate < sampleCollectionDate) {
        newErrors[
          "Time of sample collection"
        ] = `Time of sample collection is before Time of lab test`;
        // newErrors['Time of lab test'] = `Time of lab Test must be after Time of sample collection`;
      }
      if (labTestDate?.toString() === sampleCollectionDate?.toString()) {
        newErrors[
          "Time of lab test"
        ] = `Time of lab Test and Time of sample collection can not be same.`;
        newErrors[
          "Time of sample collection"
        ] = `Time of sample collection and Time of lab Test can not be same.`;
      }
    }
    let newData = { ...data };
    if (data["Shutdown Datetime"] && !data["Resolved Datetime"]) {
      newData = { ...data, "Resolved Datetime": "" };
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    editData(data, formId, dataInfo.id)
      .then((response) => {
        // Close the modal
        onHide();
        getFormInfo();
      })
      .catch((error) => {
        updateMessage("error", error.message);
      });
  };

  const handleUpdate = (e, field, type) => {
    setErrors({
      ...errors,
      [field]: "",
    });

    if (type == "datetime") {
      const value = e.target.value;

      // Check if the input is in the format of `YYYY-MM-DDTHH:mm`
      const yearPart = value.split("-")[0];
      if (yearPart.length > 4) {
        // Trim the year to 4 digits if it exceeds 4 digits
        const trimmedValue = value.replace(/^(\d{4})\d*/, "$1");
        e.target.value = trimmedValue;
      }
    }

    setData((prevValues) => {
      // Check if the updated field is the masterField
      if (field === masterFeildName) {
        const newValues = dependentFieldMap[e.target.value];
        return {
          ...prevValues,
          ...newValues,
          [field]: e.target.value,
        };
      }
      // If not the masterField, just update the specific field
      return {
        ...prevValues,
        [field]: e.target.value,
      };
    });
  };

  const handlePosition = (field) => {
    if (userPosition) {
      setData((prevValues) => {
        return { ...prevValues, [field]: userPosition };
      });
    } else {
      console.log("Geolocation is not available");
    }
  };

  const handleCheckboxChange = (event, choice, field_name) => {
    const selectedOptions = data?.[field_name] || [];

    if (choice == "Other - Please Specify" && event.target.checked) {
      setData((prevState) => ({
        ...prevState,
        [field_name]: [choice],
      }));
      return;
    }

    if (event.target.checked) {
      // Add the checked value to the array
      setData((prevState) => ({
        ...prevState,
        [field_name]: [...selectedOptions, choice],
      }));
    } else {
      // Remove the unchecked value from the array
      setData((prevState) => ({
        ...prevState,
        [field_name]: selectedOptions.filter((option) => option !== choice),
      }));
    }
  };

  // render each field by type
  const renderFields = (field) => {
    if (data) {
      switch (field.type) {
        case "datetime":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                type="datetime-local"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
                format="YYYY-MM-DDThh:mm" // Format to yyyy-mm-ddThh:mm
              />
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
              {/* <DatePicker
                selected={data[field.name] ? new Date(data[field.name]) : null}
                placeholderText="YYYY-MM-DD, HH:mm"
                onChange={(e) => {
                  console.log('e==========+>', e)
                  e = {
                    target: {
                      value: e
                    }
                  }
                  handleUpdate(e, field.name, field.type)
                }}
                className="form-control date-picker-custom"
                dateFormat="yyyy-MM-dd, HH:mm"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={1}
                required={field.required}
                // value={data[field.name]}
                icon={<CiCalendar />}
              /> */}
            </Form.Group>
          );

        case "decimal":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
            </Form.Group>
          );

        case "date":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                type="date"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
                format="YYYY-MM-DD" // Format to yyyy-mm-dd
              />
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
            </Form.Group>
          );

        case "choice":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                as="select"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              >
                {field.choices?.map((choice, index) => (
                  <option key={index} value={choice}>
                    {choice}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
            </Form.Group>
          );

        case "master_choice":
          return field?.isMultiselect ? (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>

              {/* Custom Dropdown with checkboxes styled like a full-width select component */}
              <Dropdown className="w-100">
                <Dropdown.Toggle
                  variant="outline-secondary"
                  className="w-100 d-flex justify-content-between align-items-center"
                  style={{
                    textAlign: "left",
                    borderColor: "#ced4da",
                    padding: "8px 12px",
                    borderRadius: "4px",
                    boxShadow: "none",
                    overflowX: "scroll",
                  }}
                >
                  {data[field.name]?.length > 0
                    ? `Selected: ${data?.[field?.name]?.join(", ")}`
                    : "Select options"}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="w-100"
                  style={{ maxHeight: "200px", overflowY: "auto" }}
                >
                  {field.choices.map((choice, index) => (
                    <Form.Check
                      key={index}
                      type="checkbox"
                      label={choice}
                      value={choice}
                      checked={data?.[field.name]?.includes(choice)}
                      onChange={(e) =>
                        handleCheckboxChange(e, choice, field.name)
                      }
                      className="mx-3"
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
            </Form.Group>
          ) : (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                as="select"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              >
                {field.choices?.map((choice, index) => (
                  <option key={index} value={choice}>
                    {choice}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
            </Form.Group>
          );

        case "text":
          if (field.name === "GPS coordinates of the farm") {
            return (
              <Form.Group key={field.name} controlId={field.name}>
                <Form.Label>
                  {field.name}
                  {field.required && <span style={{ color: "red" }}>*</span>}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={data[field.name]}
                  onChange={(e) => handleUpdate(e, field.name, field.type)}
                  required={field.required}
                  isInvalid={!!errors[field.name]}
                />
                <Button
                  variant="outline-secondary"
                  onClick={(e) => handlePosition(field.name)}
                >
                  Get my GPS
                </Button>
              </Form.Group>
            );
          } else {
            return (
              <Form.Group key={field.name} controlId={field.name}>
                <Form.Label>
                  {field.name}
                  {field.required && <span style={{ color: "red" }}>*</span>}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={data[field.name]}
                  onChange={(e) => handleUpdate(e, field.name, field.type)}
                  required={field.required}
                  isInvalid={!!errors[field.name]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors[field.name]}
                </Form.Control.Feedback>
              </Form.Group>
            );
          }

        case "text-large":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                type="text"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
            </Form.Group>
          );

        case "boolean":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Check
                type="checkbox"
                value={data[field.name]}
                label={field.name}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
            </Form.Group>
          );

        case "time":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                type="time"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
            </Form.Group>
          );

        case "master_value":
          return (
            <Form.Group key={field.name} controlId={field.name}>
              <Form.Label>
                {field.name}
                {field.required && <span style={{ color: "red" }}>*</span>}
              </Form.Label>
              <Form.Control
                disabled={true}
                type="text"
                value={data[field.name]}
                onChange={(e) => handleUpdate(e, field.name, field.type)}
                required={field.required}
                isInvalid={!!errors[field.name]}
              />
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
            </Form.Group>
          );

        default:
          return null;
      }
    }
  };

  const handleClose = () => {
    setErrors({});
    onHide();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>{template && template?.map((field) => renderFields(field))}</Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Edit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DataEditModal;
