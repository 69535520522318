import React, { useState } from "react";
import {
  Modal,
  Form,
  Button,
  Badge,
  CloseButton,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import { isEmail, isEmpty } from "validator";

import { resendEmail } from "../../hooks/resendEmail";
import "./style.css";

// Function to filter emails
const filterEmailsThaHaveSent = (emails, extraResponseData) => {
  const { validUnverifiedEmails, invalidUnregistedEmails, emailsCaughtError } =
    extraResponseData;

  // Combine all excluded emails into one array
  const excludedEmails = [
    ...validUnverifiedEmails,
    ...invalidUnregistedEmails,
    ...emailsCaughtError,
  ];
  // Filter out emails that are present in the excludedEmails array
  const filteredEmails = emails.filter(
    (email) => !excludedEmails.includes(email)
  );
  // Update the emails state with the filtered emails
  return filteredEmails || [];
};

const ResendEmailModal = ({ show, onHide, updateMessage }) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [extraResponseData, setExtraResponseData] = useState({
    validUnverifiedEmails: [],
    invalidUnregistedEmails: [],
    emailsCaughtError: [],
    sentEmailAddresses: [],
  });

  // sending email for reset password
  const handleResendEmail = () => {
    setMessage("");

    setExtraResponseData({
      validUnverifiedEmails: [],
      emailsCaughtError: [],
      invalidUnregistedEmails: [],
      sentEmailAddresses: [],
    });

    if (emails.length === 0) {
      setError("Email Address is required");
      return;
    }
    resendEmail({ emails: emails })
      .then((res) => {
        console.log("respomnse :: ", res);
        setMessage(res?.message);
        res.extra.sentEmailAddresses = filterEmailsThaHaveSent(
          emails,
          res?.extra
        );
        setExtraResponseData(res?.extra);
        setMessageType("success");
        setEmails([]);
      })
      .catch((error) => {
        // updateMessage("error", `[${error.message}] Failed to resend email!`);
        setMessage(error.message);
        setMessageType("danger");
      });
  };

  // add email field
  const handleAddEmailField = () => {
    if (isEmpty(emailAddress)) {
      setError("Email Address is required");
      return;
    }
    if (!isEmail(emailAddress)) {
      setError("Invalid email address");
      return;
    }
    if (emails.includes(emailAddress)) {
      // setError("Email already exists");
      setError("Email address already exists");
      return;
    }
    setEmailAddress("");
    setEmails([...emails, emailAddress]);
  };

  const handleEmailChange = (value) => {
    setError("");
    setEmailAddress(value);
  };

  // remove email field
  const handleRemoveEmailField = (email) => {
    setEmails(emails.filter((mail) => mail !== email));
    setExtraResponseData({
      validUnverifiedEmails: [],
      emailsCaughtError: [],
      invalidUnregistedEmails: [],
      sentEmailAddresses: [],
    });
  };

  const handleHide = () => {
    setEmails([]);
    setEmailAddress("");
    setError("");
    setMessage("");
    setMessageType("");
    setExtraResponseData({
      validUnverifiedEmails: [],
      invalidUnregistedEmails: [],
      emailsCaughtError: [],
      sentEmailAddresses: [],
    });
    onHide();
  };
  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Resend Email</Modal.Title>
      </Modal.Header>
      {message && (
        <Alert variant={messageType} className="custom-alert">
          {message}{" "}
          {extraResponseData.sentEmailAddresses.length !== 0 &&
            `: ${extraResponseData.sentEmailAddresses.join(", ")}`}
        </Alert>
      )}
      {extraResponseData.validUnverifiedEmails.length !== 0 && (
        <Alert variant={"success"} className="custom-alert">
          {`Verification email has been sent to: ${extraResponseData.validUnverifiedEmails.join(
            ", "
          )}`}
        </Alert>
      )}
      {extraResponseData.invalidUnregistedEmails.length !== 0 && (
        <Alert variant={"danger"} className="custom-alert">
          {`Email addresses are not recognized: ${extraResponseData.invalidUnregistedEmails.join(
            ", "
          )}`}
        </Alert>
      )}
      {extraResponseData.emailsCaughtError.length !== 0 && (
        <Alert variant={"warning"} className="custom-alert">
          {`Email not sent to following users: ${extraResponseData.emailsCaughtError.join(
            ", "
          )}`}
        </Alert>
      )}
      <Modal.Body>
        <Form className="email-form">
          <Row className="align-items-center">
            {emails?.map((email, index) => (
              <Col xs="auto">
                <Form.Group key={index}>
                  <Form.Label>
                    <h5 className="badge-container-box">
                      <Badge
                        pill
                        variant=""
                        bg="secondary"
                        className="removable-badge"
                      >
                        {email}
                        <Button
                          variant="text"
                          size="md"
                          className="badge-close-btn"
                          onClick={() => handleRemoveEmailField(email)}
                          aria-label="Close"
                        >
                          x
                        </Button>
                      </Badge>
                      {/* <Badge bg="secondary" pill className="badge-btn">
                    {email}
                    <span className="close-btn">
                      <CloseButton
                        onClick={() => handleRemoveEmailField(email)}
                      />
                    </span>
                  </Badge> */}
                    </h5>
                  </Form.Label>
                </Form.Group>
              </Col>
            ))}
          </Row>

          <Col>
            <Form.Control
              type="email"
              placeholder="Enter Email Address"
              value={emailAddress}
              onChange={(e) => handleEmailChange(e.target.value)}
              isInvalid={error}
            />
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          </Col>
          <Button variant="primary" onClick={handleAddEmailField}>
            Add
          </Button>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleResendEmail} disabled={emails.length == 0}>
          Resend Email
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResendEmailModal;
